<template>
  <!-- Combo box menu selected -->
  <div>

    <b-input-group prepend="CNPJ">
      <b-form-select
        :id="selectClient.id"
        v-model="selected"
        :options="options"
      />
    </b-input-group>

  </div>
</template>

<script>
import axios from 'axios'
import {

  BInputGroup,
  BFormSelect,

} from 'bootstrap-vue'

export default {
  name: 'ComboBoxClient',
  components: {

    BInputGroup,
    BFormSelect,

  },
  data() {
    return {
      showCard1: false,
      showCard2: false,
      selected: null,
      options: [
        { value: null, text: 'Carregando' },
      ],
      selectClient: {
        id: 'select-client',
      },
    }
  },

  created() {
    this.getClient()
  },

  methods: {

    async getClient() {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const allowedCNPJ = userData.cnpj
        const allowedGroup = userData.group

        const responseFiltered = []

        // const res = await axios.get(`/companyuser/?skip=0&limit=100`)

        const res = await axios.get('/companyuser/?skip=0&limit=100')

        res.data.forEach(item => {
          // Validating allowed CNPJ
          for (let i = 0; i < allowedCNPJ.length; i += 1) {
            if (allowedCNPJ[i] === item.id_cnpj && allowedGroup !== 'admin') {
              console.log(allowedCNPJ[i])
              responseFiltered.push({
                value: item.id_cnpj,
                text: `${item.id_cnpj} - ${item.name}`,
              })
            }
          }

          // If the user is admin
          if (allowedGroup === 'admin') {
            responseFiltered.push({
              value: item.id_cnpj,
              text: `${item.id_cnpj} - ${item.name}`,
            })
          }

          this.options.text = 'Selecione o cliente'
        })
        this.options = responseFiltered
        // Adicionar a opção "todos"
        responseFiltered.push({
          value: 'todos',
          text: 'Todos os clientes',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
